<template>
  <div
    ref="dropdownTrigger"
    data-cy="dropdownWrapper"
    class="relative flex flex-col flex-1 select-none"
    :class="[
      { open: showMenu },
      disabled && !$slots['toggle-button'] && !asBadge
        ? 'bg-gray-50'
        : asBadge
          ? 'bg-transparent'
          : 'bg-white'
    ]"
    :name="name"
  >
    <slot
      name="toggle-button"
      :toggle="toggleMenu"
      :value="inputValue"
      :menu-open="showMenu"
    >
      <lf-badge
        v-if="asBadge"
        class="cursor-pointer text-xs font-medium border-2 transition-all duration-300 badge"
        :class="
          !inputValue && warningAsError
            ? 'bg-red-100  border-red-200'
            : 'border-transparent'
        "
        is-rounded
        @click.stop="toggleMenu"
      >
        <div class="flex py-0-25 space-x-2">
          <icon-base
            v-if="!inputValue && showWarningIcon"
            :name="`${name}-warning-icon`"
            class="transform translate-y-0-25 transition-all duration-300"
            :class="warningAsError ? 'text-error' : 'text-warning'"
            icon="warning"
            height="16"
            width="16"
          />
          <span v-if="showPlaceholder">
            {{ placeholder }}
          </span>
          <span v-else class="badge-value">
            {{ label }}
          </span>
          <span class="flex items-center text-gray-400">
            <icon-base
              class="transform transition-all"
              height="6"
              width="10"
              icon="dropdown-arrow"
              :class="{ 'rotate-180': showMenu }"
            />
          </span>
        </div>
      </lf-badge>
      <template v-else>
        <span
          v-show="
            showLabel &&
            (inputValue || Number.isInteger(inputValue)) &&
            !hidePlaceholderWhenValue
          "
          class="z-1 absolute ml-2-5 px-1-25 w-min text-xs -mt-3 whitespace-nowrap"
          :class="[
            disabled ? 'bg-gray-50' : 'bg-white',
            showMenu ? 'text-blue-500' : 'text-gray-400'
          ]"
        >
          {{ placeholder }}
        </span>
        <button
          v-if="!filterIcon"
          type="button"
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="relative w-full pl-4 pr-8 text-left text-sm rounded py-1 flex items-center dropdown-button focus:outline-none"
          :class="[
            dropdownButtonHeight,
            { 'border-red-600': !!errorMessage },
            { 'bg-gray-50': disabled },
            { 'border-none text-xl font-semibold': titleDropdown },
            { capitalize: capitalize },
            showMenu
              ? 'border-primary hover:border-primary border-2'
              : 'border hover:border-gray-300',
            showGrayHeadlineText ? 'text-gray-400' : 'text-headline'
          ]"
          :disabled="disabled"
          :role="`${name}-dropdown`"
          :value="inputValue ? `${inputValue}` : undefined"
          @click.stop="toggleMenu"
        >
          <span v-if="!!icons && inputValue && optionIcon(inputValue).icon">
            <icon-base
              :icon="optionIcon(inputValue).icon"
              :view-box="optionIcon(inputValue).viewBox"
              :class="optionIcon(inputValue).class"
              class="mr-3"
            />
          </span>
          <img
            v-if="!!icons && inputValue && !optionIcon(inputValue).icon"
            :src="optionIcon(inputValue)"
            style="width: 20px; height: 20px"
            class="mr-3 inline-block"
            :alt="$t('COMMON.OPTION_ICON', { option: inputValue })"
            loading="lazy"
          />
          <span
            class="block whitespace-nowrap text-ellipsis overflow-hidden input-value"
          >
            {{ label }}
          </span>
          <slot name="type" />
          <span
            class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer text-gray-400 dropdown-icon"
          >
            <icon-base
              height="6"
              width="10"
              icon="dropdown-arrow"
              :class="{ 'transform rotate-180': showMenu }"
            />
          </span>
        </button>
      </template>
    </slot>
    <field-error-message :value="errorMessage" />
    <teleport to="#menus" :disabled="!teleportOptions.enabled">
      <div
        v-show="toggleFromParent || showMenu"
        ref="dropdown"
        class="dropdown bg-white absolute shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none overflow-hidden"
        :class="[
          dropUp ? 'bottom-full' : filterIcon ? 'top-3' : 'top-full',
          fullWidth && 'w-full',
          !fullWidth && menuWidth,
          {
            'left-0': !teleportOptions.enabled && !flipElement,
            'right-0': !teleportOptions.enabled && flipElement,
            'w-30': filterIcon
          },
          customZIndex ?? 'z-50'
        ]"
        :style="dropdownStyle"
      >
        <div v-if="searchEnabled" class="px-2 py-2 min-w-[265px] w-full">
          <search-input
            :model-value="search"
            :search-term="searchPlaceholder || placeholder"
            no-padding
            no-margin
            @update:model-value="handleSearchChange"
          />
        </div>
        <hr v-if="$slots.search" />
        <div class="relative">
          <loader :is-loading="isLoading" />
          <ul
            id="exp_elem_list"
            :data-cy="`dropdown-${name}`"
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-item-3"
            class="text-sm text-gray-500 max-h-44 overflow-y-auto z-50 w-full overscroll-none"
          >
            <template v-for="(option, idx) in sortedOptions" :key="idx">
              <slot :option="option" name="listItem">
                <li
                  :data-cy="option"
                  class="py-2 pr-2 hover:bg-gray-200 flex justify-start overflow-auto w-full dropdown-option"
                  role="option"
                  :aria-selected="isSelected(idx)"
                  :class="{ 'bg-gray-100': isSelected(idx) }"
                  @click="selectOption(idx)"
                >
                  <slot
                    name="label"
                    :is-selected="isSelected(idx)"
                    :option="option"
                    :option-id="idx"
                  >
                    <template v-if="!!icons">
                      <span v-if="optionIcon(idx).icon" class="icon">
                        <icon-base
                          :icon="optionIcon(idx).icon"
                          :view-box="optionIcon(idx).viewBox"
                          :class="optionIcon(idx).class"
                        />
                      </span>
                      <img
                        v-else
                        :src="optionIcon(idx)"
                        style="width: 20px; height: 20px"
                        class="ml-3"
                        :alt="$t('COMMON.OPTION_ICON', { option: inputValue })"
                        loading="lazy"
                      />
                    </template>
                    <span
                      class="ml-3 font-normal"
                      :class="[
                        {
                          capitalize
                        },
                        isOptionDisabled(option)
                          ? 'text-gray-300'
                          : 'cursor-pointer'
                      ]"
                    >
                      {{ optionFormatter ? optionFormatter(option) : option }}
                    </span>
                  </slot>
                </li>
              </slot>
            </template>

            <template v-if="isEmpty(sortedOptions)">
              <p class="text-center p-2">
                {{ customNoDataMessage || $t("COMMON.NO_DATA_AVAILABLE") }}
              </p>
            </template>
            <div
              v-show="showClearFilter || $slots.action"
              class="bg-white sticky bottom-0 flex items-center p-0 h-11 border-t justify-between px-2"
            >
              <a
                v-if="showClearFilter"
                class="text-primary text-sm cursor-pointer font-medium transform active:scale-95"
                @click.stop="clearFilter"
              >
                {{ clearText || $t("COMMON.CLEAR_FILTER") }}
              </a>
              <slot name="action" :search-string="search" />
            </div>
          </ul>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ref,
  watch,
  type PropType,
  readonly,
  onMounted,
  onBeforeUnmount
} from "vue";
import { useField } from "vee-validate";
import { onClickOutside } from "@vueuse/core";
import { sortProperties } from "@/helpers/formatting";
import { useTeleport } from "@/hooks/elements";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import FieldErrorMessage from "@/components/ui/inputs/FieldErrorMessage.vue";
import LfBadge from "@/components/ui/LfBadge.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import { getDropdownWidth, checkElementIsFullyVisible } from "@/helpers/UI";

export interface DropdownTeleportOptions {
  enabled: boolean;
  scrollable: string;
  horizontalScrollable: string;
}

const defaultTeleportOptions: DropdownTeleportOptions = {
  enabled: false,
  horizontalScrollable: "",
  scrollable: "dealsWrapper"
};

/* eslint-disable @typescript-eslint/no-explicit-any */
defineSlots<{
  "toggle-button"?: (props: {
    toggle: () => void;
    value: string | number | boolean;
    menuOpen: boolean;
  }) => any;
  label: (props: { isSelected: boolean; option: any; optionId: any }) => any;
  action: (props: { searchString: string }) => any;
  type?: (props: Record<string, never>) => any;
  listItem: (props: { option: unknown }) => any;
}>();
/* eslint-enable @typescript-eslint/no-explicit-any */

const emit = defineEmits<{
  "menu:toggle": [];
  "search:change": [value: string];
  toggle: [value: boolean];
  change: [option: string | number];
}>();

const props = defineProps({
  options: {
    type: Object,
    default: () => ({})
  },
  icons: {
    type: Object,
    default: null
  },
  name: {
    type: String,
    default: ""
  },
  value: {
    type: [String, Number, Boolean],
    default: ""
  },
  placeholder: {
    type: String,
    default: ""
  },
  sort: {
    type: Boolean,
    default: false
  },
  hidePlaceholderWhenValue: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  dropUp: {
    type: Boolean,
    default: false
  },
  titleDropdown: {
    type: Boolean,
    default: false
  },
  showClearFilter: {
    type: Boolean,
    default: false
  },
  clearText: {
    type: String,
    default: ""
  },
  active: {
    type: Boolean,
    default: false
  },
  showLabel: {
    type: Boolean,
    default: true
  },
  capitalize: {
    type: Boolean,
    default: true
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  filterIcon: {
    type: Boolean,
    default: false
  },
  toggleFromParent: {
    type: Boolean,
    default: null
  },
  fullWidth: {
    type: Boolean,
    default: false
  },
  customNoDataMessage: {
    type: String,
    default: ""
  },
  asBadge: {
    type: Boolean,
    default: false
  },
  customButtonHeight: {
    type: String,
    default: ""
  },
  teleportOptions: {
    type: Object as PropType<Partial<DropdownTeleportOptions>>
  },
  searchEnabled: {
    type: Boolean,
    default: false
  },
  searchPlaceholder: {
    type: String,
    default: ""
  },
  warningAsError: {
    type: Boolean,
    default: false
  },
  showWarningIcon: {
    type: Boolean,
    default: true
  },
  showPlaceholder: {
    type: Boolean,
    default: false
  },
  optionFormatter: {
    type: Function
  },
  disabledOptions: {
    type: Array as PropType<Array<string> | Array<number>>,
    default: () => []
  },
  menuWidth: {
    type: String
  },
  customZIndex: {
    type: String
  }
});

const teleportOptions = computed(() => ({
  ...defaultTeleportOptions,
  ...props.teleportOptions
}));
const showMenu = ref(false);
const flipElement = ref(false);
const dropdownTrigger = ref<HTMLElement | null>(null);
const dropdown = ref<HTMLElement | null>(null);
const search = ref("");
const minWidth = ref<null | string>(null);
const cachedOptions = ref<typeof props.options>({ ...props.options });

const { top, left } = useTeleport(
  dropdownTrigger,
  teleportOptions.value.scrollable,
  "menu",
  null,
  teleportOptions.value.horizontalScrollable
);

const {
  value: inputValue,
  errorMessage,
  handleChange
} = useField(props.name, undefined, {
  initialValue: props.value
});

const showGrayHeadlineText = computed(() => {
  return (
    props.disabled ||
    (inputValue.value !== 0 && inputValue.value !== false && !inputValue.value)
  );
});

const dropdownStyle = computed(() => {
  if (!teleportOptions.value.enabled) {
    return { minWidth: minWidth.value ?? undefined };
  }
  return {
    top: top.value + 10 + "px",
    left: left.value + "px",
    minWidth: minWidth.value ?? undefined
  };
});

const label = computed(() => {
  const valueFromOptions =
    props.options[`${inputValue.value}`] ||
    cachedOptions.value[`${inputValue.value}`];
  if (valueFromOptions) {
    return props.optionFormatter?.(valueFromOptions) || valueFromOptions;
  }
  return props.value || inputValue.value || props.placeholder;
});

const sortedOptions = computed(() => {
  const options = props.sort ? sortProperties(props.options) : props.options;
  const searchValue = search.value.trim();

  if (!searchValue) {
    return options;
  }
  return Object.fromEntries(
    Object.entries(options).filter(([, option]: [string, string]) =>
      option.toLowerCase().includes(searchValue.toLowerCase())
    )
  );
});

const dropdownButtonHeight = computed(() => props.customButtonHeight || "h-10");

const optionIcon = (id: string | number | boolean) => {
  return props.icons[String(id).trim()];
};

const selectOption = (option: string | number) => {
  const optionValue = props.options[option];
  if (props.disabledOptions?.length && isOptionDisabled(optionValue || "")) {
    return;
  }
  if (typeof option === "string") {
    option = option.trimStart();
  }
  handleMenu(false);
  handleChange(option);
  emit("change", option);
};

const toggleMenu = () => {
  emit("menu:toggle");
  handleMenu(!showMenu.value);
};

const handleMenu = (value: boolean) => {
  showMenu.value = value;
  emit("toggle", value);
  emit("menu:toggle");
};

const checkVisibility = (entry: ResizeObserverEntry | Event) => {
  if (!entry.target) {
    return;
  }
  const element = entry.target as HTMLElement;
  if (!checkElementIsFullyVisible(element)) {
    flipElement.value = true;
    return;
  }
  flipElement.value = false;
};

onClickOutside(dropdown, (event) => {
  if (!showMenu.value) {
    return;
  }
  event.stopPropagation();
  handleMenu(false);
});

const clearFilter = () => {
  handleChange("");
  emit("change", "");
  handleMenu(false);
};

const isSelected = (val: string | number) => {
  if (typeof val === "string") {
    val = val.trimStart();
  }
  return val == inputValue.value;
};

const handleSearchChange = (value: string) => {
  search.value = value;
  emit("search:change", value);
};

const isOptionDisabled = (option: string): boolean =>
  props.disabledOptions?.map(String).includes(option);

watch(
  () => props.value,
  (updatedValue) => {
    handleChange(updatedValue);
  }
);

watch(
  () => props.active,
  (updatedActive) => {
    handleMenu(updatedActive);
  }
);

watch(
  () => props.options,
  () => {
    minWidth.value = getDropdownWidth(Object.values(props.options));
  },
  { immediate: true }
);

watch(
  () => props.options,
  (newOptions) => {
    cachedOptions.value = {
      ...cachedOptions.value,
      ...newOptions
    };
  }
);

const resizeObserver = new ResizeObserver((entries) => {
  setTimeout(() => {
    const [element] = entries;
    if (element.target) {
      checkVisibility(element);
    }
  }, 0);
});

onMounted(() => {
  if (isNull(dropdown.value)) {
    return;
  }
  resizeObserver.observe(dropdown.value);
  window.addEventListener("resize", () => handleMenu(false));
});

onBeforeUnmount(() => {
  if (isNull(dropdown.value)) {
    return;
  }
  resizeObserver.disconnect();
  window.removeEventListener("resize", () => handleMenu(false));
});

defineExpose({ search, showMenu: readonly(showMenu) });
</script>

<style>
.document-upload-dd ul {
  @apply w-auto;
}

.document-upload-dd li {
  @apply min-w-max;
}
</style>
